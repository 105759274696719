import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ChannelTypeInternal, DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { convertDateToFormat } from '../../../utils/helpers';

export const useConfigurationDetails = () => {
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId });
  const { channelState } = useChannelsState();
  const status = device?.recoveryMode?.status;
  const progress = device?.recoveryMode?.progress;

  const isDeviceInRecovery = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      ),
    [deviceChannels, channelState],
  );

  const registeredAt = useMemo(
    () =>
      device?.payload.registeredAtUtc
        ? convertDateToFormat(new Date(device.payload.registeredAtUtc), 'dd.MM.yy, HH:mm:ss')
        : '-',
    [device?.payload.registeredAtUtc],
  );

  const isDeviceConnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Connected,
      ),
    [deviceChannels, channelState],
  );

  const isDeviceDisconnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      ),
    [deviceChannels, channelState],
  );

  const lastConnectedAt = useMemo(
    () =>
      device?.payload.lastConnectedAtUtc
        ? convertDateToFormat(new Date(device.payload.lastConnectedAtUtc), 'dd.MM.yy, HH:mm:ss')
        : '-',
    [device?.payload.lastConnectedAtUtc],
  );

  return {
    isDeviceInRecovery,
    registeredAt,
    status,
    progress,
    isDeviceConnected,
    isDeviceDisconnected,
    lastConnectedAt,
  };
};
